const theme = {
  brandPrimaryLight: 'hsla(41, 98%, 48%, 1)',
  brandPrimary: 'hsla(41, 100%, 48%, 1)',
  brandPrimaryDark: 'hsla(41, 76%, 48%, 1)',

  brandSecondary: 'hsla(0, 0%, 0%, 1)',

  monoLightest: 'hsla(0, 0%, 100%, 1)',
  monoLight: 'hsla(0, 0%, 65%, 1)',
  mono: 'hsla(0, 0%, 45%, 1)',
  monoDark: 'hsla(0, 0%, 24%, 1)',
  monoDarkest: 'hsla(210, 11%, 15%, 1)'
}

export default theme
