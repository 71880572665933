/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react'
import theme from './src/theme'

import { ThemeProvider } from 'styled-components'
import '@debijenkorf/core-assets/dist/core-assets.min.css'
import './src/styles/typography.css'
import './src/styles/global.css'

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>{element}</ThemeProvider>
)
